import ArrowRightAlt from 'svgs/arrow-right-alt.svg'
import ArabFlagIcon from 'svgs/flag/arab.svg'
import BrazilFlagIcon from 'svgs/flag/brazil.svg'
import ChinaFlagIcon from 'svgs/flag/china.svg'
import EnglishFlagIcon from 'svgs/flag/english.svg'
import FranceFlagIcon from 'svgs/flag/france.svg'
import HongkongFlagIcon from 'svgs/flag/hongkong.svg'
import IndoFlagIcon from 'svgs/flag/indo.svg'
import ItalianFlagIcon from 'svgs/flag/italian.svg'
import JapaneseFlagIcon from 'svgs/flag/japan.svg'
import KoreaFlagIcon from 'svgs/flag/korea.svg'
import PortugalFlagIcon from 'svgs/flag/portugal.svg'
import RussianFlagIcon from 'svgs/flag/russia.svg'
import SpainFlagIcon from 'svgs/flag/spain.svg'
import ThailandFlagIcon from 'svgs/flag/thailand.svg'
import TurkeyFlagIcon from 'svgs/flag/turkey.svg'
import VietnameseFlagIcon from 'svgs/flag/vietnamese.svg'

import GameIcon from 'svgs/all-game-icon.svg'
import ShareArrowIcon from 'svgs/arrow-up-alt.svg'
import CasinoGameIcon from 'svgs/casino.svg'
import CouponIcon from 'svgs/coupon.svg'
import FilledSuccessStatus from 'svgs/filled-success-status-alt.svg'
import FilledSuccessStatusAlt from 'svgs/filled-success-status.svg'
import FilledWhiteSuccessStatus from 'svgs/filled-white-success-icon.svg'
import LikeAltIcon from 'svgs/like-alt.svg'
import LikeIcon from 'svgs/like.svg'
import SportGameIcon from 'svgs/sport.svg'
import SuccessStatus from 'svgs/success-status.svg'
import TipIcon from 'svgs/tip-icon.svg'
import UnlikeIcon from 'svgs/unlike.svg'

import BlockIcon from 'svgs/block-icon.svg'
import MenuBarIcon from 'svgs/menu-bar.svg'
import MenuIcon from 'svgs/menu.svg'
import OutlinedUserIcon from 'svgs/user-icon-outlined.svg'
import UserIcon from 'svgs/user-icon.svg'

import ArrowDownIconAlt from 'svgs/arrow-down-alt.svg'
import ArrowDownIcon from 'svgs/arrow-down.svg'
import ArrowLeftMainBanner from 'svgs/arrow-left-main-banner.svg'
import ArrowLeftIcon from 'svgs/arrow-left.svg'
import ArrowRightMainBanner from 'svgs/arrow-right-main-banner.svg'
import ArrowRightPrimaryColorIcon from 'svgs/arrow-right-primary-color.svg'
import ArrowRight from 'svgs/arrow-right.svg'
import ArrowUpIcon from 'svgs/arrow-up.svg'
import BonusIcon from 'svgs/bonus.svg'
import BrowserIcon from 'svgs/browser.svg'
import CloseCircleIcon from 'svgs/close-circle.svg'
import CloseStrokeIcon from 'svgs/close-stroke.svg'
import CloseIcon from 'svgs/close.svg'
import ComingSecondary from 'svgs/coming-secondary.svg'
import CopyIconOutline from 'svgs/copy-icon-outline.svg'
import CopyIcon from 'svgs/copy-icon.svg'
import CopyRightIcon from 'svgs/copy-right.svg'
import DashedLineIcon from 'svgs/dashed-line.svg'
import EmailIcon from 'svgs/email-default.svg'
import ExpandIcon from 'svgs/expand.svg'
import HunnyFarmIcon from 'svgs/farm.svg'
import FullScreenIcon from 'svgs/fullscreen.svg'
import HunnyPokerIcon from 'svgs/hunnypoker.svg'
import HunnySwapIcon from 'svgs/hunnyswap.svg'
import JarIcon from 'svgs/jar.svg'
import NewSecondary from 'svgs/new-secondary.svg'
import NewIcon from 'svgs/new.svg'
import NewsIcon from 'svgs/news.svg'
import PlayAltIcon from 'svgs/play-alt.svg'
import PlayIcon from 'svgs/play.svg'
import ReferralIcon from 'svgs/referral.svg'
import StakeIcon from 'svgs/stake.svg'
import TransactionHistoryIcon from 'svgs/transaction-history.svg'
import VIPClubIcon from 'svgs/vipclub.svg'

import AddIconOutlined from 'svgs/add-icon.svg'
import BackIcon from 'svgs/back.svg'
import BinIcon from 'svgs/bin.svg'
import CashbackIcon from 'svgs/cashback.svg'
import ChevronLeftIcon from 'svgs/chevron-left.svg'
import ChevronRightIcon from 'svgs/chevron-right.svg'
import ClearIcon from 'svgs/clear.svg'
import CupAltIcon from 'svgs/cup-alt.svg'
import CupIcon from 'svgs/cup.svg'
import DashboardIcon from 'svgs/dashboard.svg'
import DateRangeIcon from 'svgs/date-range-icon.svg'
import DownloadIcon from 'svgs/download.svg'
import EditIcon from 'svgs/edit-icon.svg'
import EditInputIcon from 'svgs/edit-input-icon.svg'
import ExternalLinkIcon from 'svgs/external-link.svg'
import EyeIcon from 'svgs/eye.svg'
import EyeRevealIcon from 'svgs/eye_reveal.svg'
import GiftOutLineIcon from 'svgs/gift-outline.svg'
import GiftIcon from 'svgs/gift.svg'
import GlobeIcon from 'svgs/globe.svg'
import HiddenAvatar from 'svgs/hidden-avatar.svg'

import CircleArrowDown from 'svgs/circle-arrow-down.svg'
import DesktopDownload from 'svgs/desktop-download.svg'
import HelpOutlineIcon from 'svgs/help-outline.svg'
import HomeIcon from 'svgs/home.svg'
import HourGlassIcon from 'svgs/hourglass.svg'
import HusdIcon from 'svgs/hpd-navigation-icon.svg'
import InfoCircleIcon from 'svgs/info-circle.svg'
import InfoOutLineIcon from 'svgs/info-outline.svg'
import InfoIcon from 'svgs/info.svg'
import LinkCircleIcon from 'svgs/link-circle.svg'
import LockIcon from 'svgs/lock.svg'
import LoginIcon from 'svgs/login.svg'
import LogoutIcon from 'svgs/logout.svg'
import MobileDownload from 'svgs/mobile-download.svg'
import NotificationIcon from 'svgs/noti-icon.svg'
import OpenPageicon from 'svgs/open-page-icon.svg'
import OutlineCheck from 'svgs/outline-check.svg'
import PasswordIcon from 'svgs/password.svg'
import PlusIcon from 'svgs/plus-icon.svg'
import ProfileIcon from 'svgs/profile.svg'
import ProfilesIcon from 'svgs/profiles.svg'
import ProviderFilterIcon from 'svgs/provider-filter.svg'
import ProviderIcon from 'svgs/provider-icon.svg'
import Pushcon from 'svgs/push-icon.svg'
import RectangleDock from 'svgs/rectangle-dock.svg'
import SearchIconAlt from 'svgs/search-alt.svg'
import SearchIcon from 'svgs/search.svg'
import SettingIcon from 'svgs/setting.svg'
import SuccessCheckIcon from 'svgs/success-check.svg'
import SuccessIcon from 'svgs/success.svg'
import SupportIcon from 'svgs/support-outline.svg'
import TicketIcon from 'svgs/ticket-icon.svg'
import TrashbinIcon from 'svgs/trash-bin-icon.svg'
import TripleArrowIcon from 'svgs/triple-arrow.svg'
import TripleDownIcon from 'svgs/triple-down-icon.svg'
import TripplePoint from 'svgs/tripple-point.svg'
import VerifyIcon from 'svgs/verify.svg'
import WalletIcon from 'svgs/vuesax/empty-wallet-add.svg'
import WarningIconCircle from 'svgs/warning-icon-circle.svg'
import WarningIcon from 'svgs/warning.svg'

import AnimationIcon from 'svgs/animation.svg'
import ApproximatelyIcon from 'svgs/approximately.svg'
import FireIcon from 'svgs/fire.svg'
import KeyboardIcon from 'svgs/keyboard.svg'
import PlinkoLoadingIcon from 'svgs/plinko-loading.svg'
import PlinkoPercentIcon from 'svgs/plinko-percent.svg'
import SettingConfigIcon from 'svgs/setting-config-icon.svg'
import SettingOutLineIcon from 'svgs/setting-outline.svg'
import ThunderIcon from 'svgs/thunder.svg'
import VolumeIcon from 'svgs/volume.svg'

import FairNessIcon from 'svgs/fairness.svg'
import QuestionIcon from 'svgs/question.svg'

import CircleLoadingBackgroundIcon from 'svgs/circle-loading-background.svg'
import CircleLoadingIcon from 'svgs/circle-loading.svg'

import DiscordIcon from 'svgs/discord.svg'
import MediumIcon from 'svgs/medium.svg'
import TelegramIcon from 'svgs/telegram.svg'
import TwitterIcon from 'svgs/twitter.svg'

import SwapIcon from 'svgs/icon-swap.svg'
import LimboLoadingIcon from 'svgs/limbo-loading.svg'
import WithdrawIcon from 'svgs/vuesax/card-send.svg'

import LiquidBottleIcon from 'svgs/liquid-bottle-icon.svg'
import BitKeepIcon from 'svgs/wallet/bitkeep.svg'
import CloverWallet from 'svgs/wallet/clover.svg'
import Coin98 from 'svgs/wallet/coin98.svg'
import MathWallet from 'svgs/wallet/math-wallet.svg'
import MetamaskIcon from 'svgs/wallet/metamask-fox.svg'
import PhantomWallet from 'svgs/wallet/phantom.svg'
import RabbyWallet from 'svgs/wallet/rabby.svg'
import SafePal from 'svgs/wallet/safepal.svg'
import SlopeWallet from 'svgs/wallet/slope.svg'
import SolflareWallet from 'svgs/wallet/solflare.svg'
import SolletWallet from 'svgs/wallet/sollet.svg'
import TokenPocket from 'svgs/wallet/token-pocket-wallet.svg'
import TonWallet from 'svgs/wallet/ton.svg'
import TrustWallet from 'svgs/wallet/trust-wallet.svg'
import BSCWallet from 'svgs/wallet/wallet-bsc.svg'
import WalletConnect from 'svgs/wallet/wallet-connect.svg'

import DiceLoadingIcon from 'svgs/dice-loading.svg'
import MineLoadingIcon from 'svgs/mine-loading.svg'
import RefreshIcon from 'svgs/refresh.svg'
import WheelLoadingIcon from 'svgs/wheel-loading.svg'

import ComputerIcon from 'svgs/computer-icon.svg'
import EwalletIcon from 'svgs/ewallet-icon.svg'
import GeoPointIcon from 'svgs/geo-point-icon.svg'
import HouseIcon from 'svgs/house-icon.svg'
import QrCodeIcon from 'svgs/qr-code-icon.svg'
import ShieldTickIcon from 'svgs/shield-tick.svg'
import ShieldIcon from 'svgs/shield.svg'

import DiscordSquareIcon from 'svgs/social/discord-square.svg'
import GoogleSquareIcon from 'svgs/social/google-square.svg'
import TelegramSquareAquaIcon from 'svgs/social/telegram-square-aqua.svg'
import TelegramSquareIcon from 'svgs/social/telegram-square.svg'
import TwitterSquareIcon from 'svgs/social/twitter-square.svg'

import StatisticIcon from 'svgs/statistic-icon.svg'

import DepositIcon from 'svgs/deposit-icon.svg'
import SecurityIcon from 'svgs/security-icon.svg'
import SuportIcon from 'svgs/support-icon.svg'
import VipStarIcon from 'svgs/vip-star-icon.svg'
import PaymentWithdrawalIcon from 'svgs/withdrawal-icon.svg'

import DartIcon from 'svgs/commitments/dart.svg'
import JoyStickIcon from 'svgs/commitments/joystick.svg'
import JudgeIcon from 'svgs/commitments/judge.svg'
import LightningIcon from 'svgs/commitments/lightning.svg'
import LockCircleIcon from 'svgs/commitments/lock-circle.svg'
import MedalIcon from 'svgs/commitments/medal.svg'
import ProtectClound from 'svgs/commitments/protect-cloud.svg'
import ProtectIcon from 'svgs/commitments/protect-icon.svg'
import RestrictIcon from 'svgs/commitments/restrict.svg'
import SquareCheckIcon from 'svgs/commitments/square-check.svg'

import OptionIcon from 'svgs/option.svg'

export const Icons = {
  ComputerIcon,
  EwalletIcon,
  HouseIcon,
  GeoPointIcon,
  QrCodeIcon,

  RefreshIcon,
  WheelLoadingIcon,
  MenuIcon,
  MineLoadingIcon,
  MenuBarIcon,
  HunnyPokerIcon,
  HunnyFarmIcon,
  JarIcon,
  HunnySwapIcon,
  TransactionHistoryIcon,
  ExpandIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  ArrowDownIconAlt,
  ArrowRight,
  ArrowLeftIcon,
  ArrowRightAlt,
  ArrowRightPrimaryColorIcon,
  ArrowLeftMainBanner,
  ArrowRightMainBanner,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClearIcon,
  StakeIcon,
  LikeIcon,
  LikeAltIcon,
  UnlikeIcon,
  NewIcon,
  NewsIcon,
  NewSecondary,
  ComingSecondary,
  ReferralIcon,
  CopyRightIcon,
  CopyIcon,
  CopyIconOutline,
  InfoCircleIcon,
  EmailIcon,
  CloseCircleIcon,
  CloseStrokeIcon,
  InfoOutLineIcon,
  CloseIcon,
  FullScreenIcon,
  UserIcon,
  OutlinedUserIcon,
  AddIconOutlined,
  EditIcon,
  EditInputIcon,
  HiddenAvatar,
  TelegramIcon,
  TwitterIcon,
  MediumIcon,
  DiscordIcon,
  SearchIcon,
  SearchIconAlt,
  BackIcon,
  HomeIcon,
  OpenPageicon,
  LoginIcon,
  SupportIcon,
  EyeIcon,
  EyeRevealIcon,
  PasswordIcon,
  TicketIcon,
  WarningIcon,
  WarningIconCircle,
  WalletIcon,
  DateRangeIcon,
  TrashbinIcon,
  CouponIcon,
  CircleLoadingBackgroundIcon,
  CircleLoadingIcon,
  InfoIcon,
  SuccessIcon,
  HourGlassIcon,
  SuccessCheckIcon,
  VerifyIcon,
  ProviderFilterIcon,
  PlayIcon,
  PlayAltIcon,
  HelpOutlineIcon,
  TripleArrowIcon,
  TripleDownIcon,
  DashboardIcon,
  GiftIcon,
  GiftOutLineIcon,
  DownloadIcon,
  QuestionIcon,
  TipIcon,
  TripplePoint,
  PlusIcon,
  MobileDownload,
  DesktopDownload,
  Pushcon,
  OutlineCheck,
  RectangleDock,
  CircleArrowDown,
  LinkCircleIcon,
  ProfilesIcon,
  ApproximatelyIcon,
  ProviderIcon,
  ExternalLinkIcon,
  ShieldIcon,
  ShieldTickIcon,

  CupIcon,
  CupAltIcon,
  ProfileIcon,
  BonusIcon,
  ShareArrowIcon,
  BrowserIcon,
  DashedLineIcon,
  SettingIcon,
  SettingOutLineIcon,
  SettingConfigIcon,
  PlinkoLoadingIcon,
  LimboLoadingIcon,
  DiceLoadingIcon,
  PlinkoPercentIcon,
  ThunderIcon,
  AnimationIcon,
  FireIcon,
  KeyboardIcon,
  VolumeIcon,
  FairNessIcon,
  VIPClubIcon,

  // navigation icons
  CashbackIcon,
  HusdIcon,
  LogoutIcon,
  NotificationIcon,

  /// payment
  WithdrawIcon,
  SwapIcon,
  DepositIcon,
  SecurityIcon,
  SuportIcon,
  VipStarIcon,
  PaymentWithdrawalIcon,

  // Commitments
  ProtectIcon,
  LockCircleIcon,
  JudgeIcon,
  DartIcon,
  JoyStickIcon,
  LightningIcon,
  MedalIcon,
  RestrictIcon,
  SquareCheckIcon,
  ProtectClound,

  // wallet icons
  Coin98,
  BitKeepIcon,
  MetamaskIcon,
  MathWallet,
  SafePal,
  TokenPocket,
  TrustWallet,
  BSCWallet,
  WalletConnect,
  PhantomWallet,
  SlopeWallet,
  SolletWallet,
  CloverWallet,
  SolflareWallet,
  TonWallet,
  RabbyWallet,

  CasinoGameIcon,
  SportGameIcon,
  GameIcon,
  BinIcon,
  BlockIcon,
  LockIcon,

  // status
  SuccessStatus,
  FilledSuccessStatus,
  FilledSuccessStatusAlt,
  FilledWhiteSuccessStatus,

  // flags
  ArabFlagIcon,
  BrazilFlagIcon,
  ChinaFlagIcon,
  EnglishFlagIcon,
  FranceFlagIcon,
  HongkongFlagIcon,
  IndoFlagIcon,
  KoreaFlagIcon,
  PortugalFlagIcon,
  SpainFlagIcon,
  ThailandFlagIcon,
  TurkeyFlagIcon,
  VietnameseFlagIcon,
  JapaneseFlagIcon,
  LiquidBottleIcon,
  RussianFlagIcon,
  ItalianFlagIcon,

  // social
  GoogleSquareIcon,
  TwitterSquareIcon,
  TelegramSquareIcon,
  DiscordSquareIcon,
  TelegramSquareAquaIcon,

  StatisticIcon,
  OptionIcon,
  GlobeIcon,
}

export const getIcon = (iconName: keyof typeof Icons | string) => {
  return Icons[iconName]
}
